.Sale {
  width: 100%;
  height: 100%;
}

.Sale h5 {
  margin: 1rem;
  font-weight: bold;
}

.currency {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.Sale-section {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  padding: 1rem;
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid rgb(167, 167, 167);
  border-radius: 5px;
}

.Sale-top-left-container {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 2rem;
  width: 100%;
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid rgb(167, 167, 167);
  border-radius: 5px;
}

.Sale-paintlist-printable {
  font-size: 1.6rem !important;
  padding: 1rem;
}

.Sale-paintlist-printable h3 {
  font-size: 2rem !important;
}

.Sale-paintlist-printable input:disabled,
select:disabled,
th {
  font-size: 1.6rem;
  color: black;
}

.Sale-top-right-container {
  position: relative;
  width: 100%;
  padding: 1rem;
}

.Sale-top-right-container .active {
  background-color: white !important;
  color: black !important;
}

.Sale-top-right-workOrder {
  margin-bottom: 3rem;
}

.Sale-top-right-workOrder input:disabled {
  width: 100%;
  background-color: white !important;
  color: rgb(121, 121, 121) !important;
  font-weight: bold;
  font-size: 1rem !important;
}

.Sale-top-right-workOrder select:disabled {
  background-color: white !important;
  width: 100%;
  color: rgb(121, 121, 121) !important;
  font-weight: bold;
  font-size: 1rem !important;
}

.Sale-top-right-payment-container {
  height: 17rem;
  overflow-y: auto;
}

.Sale-top-right-payment-container-balance {
  display: flex;
  align-items: center;
}

.Sale-top-right-payment-container-balance p {
  margin: 0;
}

.Sale-top-right-ra-section {
  padding: 1rem;
  height: 30rem;
  overflow-y: auto;
}

.payment-card {
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  background-color: #fafafa;
}

.payment-card .close {
  font-size: 0.9rem !important;
}

.payment-card p {
  font-size: 0.9rem;
  margin: 0;
}

.Sale-top-right-payment-container-button {
  display: flex;
  padding-top: 1rem;
  padding-left: 1rem;
  align-items: center;
  width: 100%;
}

.Sale-top-right-payment-container-button button {
  font-size: 0.8rem;
}

.Sale-form-input {
  border: none;
  background-color: none;
  outline: none;
}

.Sale-form-input-row {
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Sale-form-input-row label {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 700;
  margin-right: 5rem;
  margin-bottom: 0;
  color: #050505;
}

.Sale-form-input-row input {
  width: 100%;
  padding: 0.3rem 2rem 0.3rem 0.3rem;
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.Sale-form-input-row input:disabled {
  background-color: #f6f6f6;
  color: rgb(121, 121, 121);
  border: none;
  font-weight: bold;
  font-size: 1rem !important;
  overflow-wrap: none;
  padding: 1rem;
}

.Sale-form-input-row select {
  width: 97%;
  padding: 0.3rem 3rem 0.3rem 0.3rem;
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.Sale-form-input-row select:disabled {
  width: 97%;
  background-color: #f6f6f6;
  color: rgb(121, 121, 121) !important;
  border: none;
  appearance: none;
  font-weight: bold;
  font-size: 1rem !important;
  padding: 1rem;
}

.note-card {
  white-space: pre-wrap;
}

.Sale-bottom-door-render {
  margin: 2rem;
  width: 60%;
}

.Sale-edit-btn {
  margin: 1rem;
}

.Sale-edit-btn .edit {
  background-color: rgb(146, 200, 235);
  border: none;
}

.Sale-edit-btn button {
  margin: 1rem;
}

.Collapsible {
  margin: 0 1rem;
  background-color: white;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: rgb(146, 200, 235);
  font-weight: bold;
  color: white;
}
.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

/* Style for File Drop */
.Sale-file-container {
  margin: 1rem;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.Sale-file-container:hover {
  background-color: #e6e6e6;
  color: black;
  border-color: #e6e6e6;
  border-style: solid;
}

.close {
  z-index: 30;
}

.close:hover {
  color: red;
}

.Sale-paintlist-header {
  display: flex;
  flex-direction: row;
}

.Sale-paintlist-header-company-details {
  background-color: #f6f6f6;
  margin: 2rem 0;
  padding: 2rem;
  border-radius: 25px;
  height: 100%;
}
.Sale-paintlist-header-company-details p {
  margin: 0;
  font-weight: normal;
}

.Sale-paintlist-header-right-container {
  margin: 2rem 0;
  padding: 2rem;
  width: 50%;
}

.Sale-paintlist-header-right-container p {
  margin: 0;
  font-weight: normal;
}

.Sale-paintlist-header-right-container input[type="date"] {
  width: 100% !important;
  border: 1px solid gray;
  border-radius: 5px;
}

.Sale-paintlist-header-right-container select {
  width: 100% !important;
  border: 1px solid gray;
  border-radius: 5px;
}

.Sale-paintList-date {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sale-orderform {
  margin-top: 1rem;
  padding: 0.5rem;
}

.Sale-orderform {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Sale-table-list tr:nth-child(even) {
  background-color: #ebebeb;
}

.Sale-order-form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Sale-paintform-input {
  border: none;
  white-space: nowrap;
  width: 100%;
}

.Sale-paintform-items {
  margin-top: 2rem;
}

.Sale-paintform-items input:enabled {
  border: 1px solid gray;
  border-radius: 5px;
}

.Sale-paintform-items select:enabled {
  border: 1px solid gray;
  border-radius: 5px;
}

@media all and (max-width: 1024px) {
  .Sale-section {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .Sale-form-input-row {
    margin: 0.5rem;
  }

  .Sale-form-input-row label {
    margin-right: 0;
    width: 50%;
  }

  .Sale-form-input-row input {
    width: 100%;
    padding: 0.3rem 2rem 0.3rem 0.3rem;
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }

  .Sale-form-input-row input:disabled {
    padding: 0.2rem;
  }

  .Sale-form-input-row select {
    width: 97%;
    padding: 0.3rem 3rem 0.3rem 0.3rem;
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }

  .Sale-form-input-row select:disabled {
    padding: 0.2rem;
  }

  .Sale-top-right-ra-section {
    padding: 0rem;
    height: 100%;
    overflow-y: auto;
  }

  .Sale-top-left-container {
    padding: 0;
  }

  .Sale-top-left-container h5 {
    margin: 0.5rem;
  }

  .Sale-top-right-payment-container {
    height: 100%;
  }

  .Sale-bottom-door-render {
    width: 100%;
    height: 100%;
    margin: 3rem 1rem;
    overflow-x: scroll;
    display: flex;
    justify-content: start;
    align-items: start;
  }

  .SidelightTwoPanelContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: red;
    width: 100%;
    height: 100%;
  }

  .Sale-top-right-payment-container-button {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
}
